<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import { VMoney } from 'v-money';
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from 'moment';

export default {
  locales: {
    en: {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    pt: {
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'Points': 'Pontos',
      'Username': 'Usuário',
      'Name': 'Nome',
      'Enter a value': 'Insira um valor',
      'Filter': 'Filtrar',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

      'Points': 'Puntos',
      'Username': 'Nombre de Usuario',
      'Name': 'Nombre',
      'Enter a value': 'Ingrese un valor',
      'Filter': 'Filtrar',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList, VueMonthlyPicker },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format('MMM/YYYY'),
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      date: moment().format('YYYY-MM'),

      value: '',

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0
      },

      table: {
        heade: [
          '', 'Username', 'Name', 'Points'
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    showDate (date) {
			this.date = date
		},
    getList: function () {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('report/points?date='+this.date+'&value='+this.value)
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
          } else if (response.data.status=='empty') {
            this.table.body = ''
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format('YYYY-MM')
      this.getList()
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Points') }}</h4>
          <div class="page-title-right d-flex">
            <vue-monthly-picker v-model="calendar" dateFormat="MMM/YYYY" :placeHolder="monthly.placeHolder" :monthLabels="monthly.labels" :clearOption="false" alignment="center"></vue-monthly-picker>
          </div>
        </div>
      </div>
    </div>

    <b-input-group prepend="Filtro de Pontos" class="mb-3">
      <b-form-input id="value" class="text-center" v-model="value" v-money="money" type="text" inputmode="decimal"></b-form-input>
      <b-input-group-append>
        <b-button type="submit" variant="default" @click="getList()">
          {{ t('Filter') }}
          <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="data" class="notranslate align-middle">
                      <div v-if="row == 'avatar'">
                        <img v-if="data" class="rounded-circle header-profile-user" :src="data" style="width:35px; height:35px;">
                        <img v-else src="@/assets/images/logo-icon.png" class="rounded-circle header-profile-user" style="width:35px; height:35px;">
                      </div>
                      <div v-else>
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>